import React from 'react'
import './SliderInterFace.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faHandsHoldingChild, faHeart, faMagnet, faSliders, faUserCheck, faUserClock } from '@fortawesome/free-solid-svg-icons';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css";
const SliderInterFace = () => {
    const cardData = [
        {
            title: 'Great Interface',
            description: 'Experience seamless navigation & stunning visuals with our app\'s intuitively designed interface.',
            icon: faGripVertical,
        },
        {
            title: 'Easy To Customise',
            description: 'Tailor your experience effortlessly with our app\'s intuitive customization options, making it uniquely yours at the touch of a button.',
            icon: faSliders,
        },
        {
            title: 'Made with Love',
            description: 'Crafted with care and passion, our app is a labor of love, designed to delight & inspire users every step of the way.',
            icon: faHeart,
        },
        {
            title: '24/7 Support',
            description: 'Unleash the power of our app with confidence, backed by our dedicated 24/7 support team ready to assist you anytime, anywhere.',
            icon: faUserClock,
        },
        {
            title: 'Trusted Users',
            description: 'Your trust is our priority, every post,every click, every time. join ou community where credibility meets connection.',
            icon: faUserCheck,
        },
        {
            title: 'Vibe with differently same',
            description: 'Discover the harmony in our differences, finding unity in every scroll. embrace the diverse beats of life, vibing with the same souls in every post."',
            icon: faMagnet,
        },
        {
            title: 'Value Your Time & Skills',
            description: 'Unlock the power of your time and talent,where every scroll adds value to your journey. Your skills shine brighter in our community, where every moment counts.',
            icon: faHandsHoldingChild,
        }
    ];
    return (
        <>
            <section className='slider-interface'>
                <Container>
                    <Row>
                        <Swiper
                            modules={[Pagination]}
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                360: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                425: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                467: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                567: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                                1440: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                            }}
                        >
                            {cardData.map((card, index, item) => (
                                <SwiperSlide key={index}>
                                    <div className='interface-card mb-5'>
                                        <div className='heading-holder'>
                                            <FontAwesomeIcon icon={card.icon} className='fa-icon' />
                                            <h2 className='text'>{card.title}</h2>
                                        </div>
                                        <div className='text-holder'>
                                            <p className='text'>{card.description}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SliderInterFace