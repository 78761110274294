import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import "./AboutTimer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
const AboutTimer = () => {
    return (
        <>
            <section className="AboutTimer">
                <Container>
                    <Row>
                        <div className="About">
                            <Row className="main-timer">
                                <Col xxl={4} xl={4} lg={4} md={4}>
                                    <div className="total-holder text-center">
                                        <h4 className="big-heading">
                                            <CountUp start={0} end={3} duration={4} decimals={0} />
                                            M+
                                        </h4>
                                        <p className="sub-text">DOWNLOADS</p>
                                    </div>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={4}>
                                    <div className="total-holder text-center">
                                        <h4 className="big-heading">
                                            <CountUp start={0} end={2.5} duration={4} decimals={0} />
                                            M+
                                        </h4>
                                        <p className="sub-text">LIVE USERS</p>
                                    </div>
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={4}>
                                    <div className="total-holder text-center">
                                        <h4 className="big-heading">
                                            <CountUp start={0} end={2.5} duration={4} decimals={0} /> M+
                                        </h4>
                                        <p className="sub-text">LIVE USERS</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="main_feature_content">
                            <Row>
                                <Col xxl={8} xl={8} lg={10} md={12} sm={12} xs={12} className="mx-auto">
                                    <div className="meet_text_div">
                                        <Row className="main">
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <div className="cotntact_div">
                                                    <div className="abcc">
                                                        <div className="icon_div_sectiom">
                                                            <FontAwesomeIcon icon={faPhone} className="fa-envelop" />
                                                        </div>
                                                        <div className="bordercircle"></div>
                                                    </div>
                                                    <div className="mobileno">
                                                        <p className="sub-text mb-0 ms-2">+91 70226 65938</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <div className="cotntact_div-two border_contact_div">
                                                    <div className="abcc">
                                                        <div className="icon_div_sectiom">
                                                            <FontAwesomeIcon icon={faEnvelope} className="fa-envelop" />
                                                        </div>
                                                        <div className="bordercircle"></div>
                                                    </div>
                                                    <div className="mobileno">
                                                        <p className="sub-text mb-0 ms-2">manimonu@meetx.co.in</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default AboutTimer;
