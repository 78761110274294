import React, { useEffect } from "react";
import "./WhyMeetX.css";
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';


const WhyMeetX = () => {

    useEffect(() => {
        AOS.init();
    }, [])


    return (
        <>
            <section className="WhyMeetX">
                <h1 className="heading">Why MeetX</h1>
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={12} data-aos="fade-right"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine" >
                            <div className="image">
                                <img
                                    className="meet-x-image"
                                    src={process.env.PUBLIC_URL + "/assest/image/homepage/WhyMeet.png"}
                                    alt="vision-man"
                                />
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={12} data-aos="fade-left"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine">
                            <div className="Why-MeetX-Content">
                                <h1 className="sub-heading">Meet X: Where Connections Flourish</h1>
                                <div className="paragraph-content">
                                    <p className="text">

                                        Experience a digital realm where every swipe brings you closer to genuine
                                        connections. With Meet X, we're not just another dating app; we're a community
                                        builder, fostering friendships, romances, and professional networks alike. Say
                                        goodbye to superficial interactions and hello to meaningful conversations that
                                        resonate.
                                    </p>

                                    <p className="text mt-3">
                                        Our platform prioritizes quality over quantity, ensuring that every match is a
                                        step towards a genuine relationship. Join Meet X today and unlock a world of
                                        possibilities – where meaningful connections await around every corner.
                                    </p>
                                </div>
                                <div className="Read-More">
                                    <Link to="/about-us"><Button variant="read" className="text">Read More</Button></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default WhyMeetX;
