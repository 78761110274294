import React, { useEffect, useRef } from 'react'
import './MainFeatures.css'
import { Col, Container, Row } from 'react-bootstrap'
const MainFeature = () => {
    const vidRef = useRef();

    useEffect(() => {
        vidRef.current.play();
    }, []);
    return (
        <>
            <section className='main-features'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-4'>
                            <h2 className='heading'>Main Feature</h2>
                        </div>
                        <Col lg={6} md={6}>
                            <div className='heading-holder mt-lg-5 mt-md-5 mt-sm-0 mt-0'>
                                <h3 className='sub-heading'>Meet Schedule</h3>
                                <p className='sub-text'>Hey! <br /> Let's hop on our social media app for a catch-up session. How about we schedule a time that suits both of our schedules?</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <video
                                className="video-class-grid"
                                src={process.env.PUBLIC_URL + "/assest/image/homepage/meetupfinal.mp4"}
                                ref={vidRef}
                                muted
                                loop
                                controls
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default MainFeature