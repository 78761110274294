import React, { useEffect } from "react";
import "./StepSection.css";
import { Col, Container, Row } from "react-bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";

const StepSection = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <section className="step-sec">
                <Container>
                    <Row>
                        <div className="heading-holder text-center mb-4">
                            <h2 className="main-heading">3 Easy Steps</h2>
                        </div>
                    </Row>

                    <Col md={11} className="ms-auto">
                        <Row className="justify-content-center">
                            <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                data-aos="fade-up"
                                data-aos-duration="2000"
                            >
                                <div className="step-card">
                                    <div className="img-holder mb-2">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assest/image/icon/download.png"}
                                            className="down-img"
                                        />
                                    </div>
                                    <div className="heading-holder mb-2">
                                        <h3>Download</h3>
                                    </div>
                                    <div className="text-holder">
                                        <p className="text">Unlock endless possibilities with just a tap – </p>
                                        <p className="text">
                                            Download our app now from the play store and experience the future in your
                                            hands.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                data-aos="fade-up"
                                data-aos-duration="2000"
                            >
                                <div className="step-card">
                                    <div className="img-holder mb-2">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assest/image/icon/call-person.png"}
                                            className="call-img"
                                        />
                                    </div>
                                    <div className="heading-holder mb-2">
                                        <h3>Register</h3>
                                    </div>
                                    <div className="text-holder">
                                        <p className="text">Embark on your journey in seconds –</p>
                                        <p className="text">
                                            Create your account effortlessly and start exploring a world of endless
                                            possibilities.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                data-aos="fade-up"
                                data-aos-duration="2000"
                            >
                                <div className="step-card">
                                    <div className="img-holder mb-2">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assest/image/icon/party.png"}
                                            className="party-img"
                                        />
                                    </div>
                                    <div className="heading-holder mb-2">
                                        <h3>Hurray</h3>
                                    </div>
                                    <div className="text-holder">
                                        <p className="text">Registration done, possibilities unlocked – </p>
                                        <p className="text">
                                            {" "}
                                            Dive into the full experience of our app with just a few clicks.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </section>
        </>
    );
};

export default StepSection;
