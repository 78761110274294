import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./WhoWeAre.css";

const WhoWeAre = () => {
    return (
        <>
            <section className="WhoWeAre">
                <Container fluid>
                    <div className="doted-image">
                        {/* <Container> */}
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={12}>
                                    <div className="Who-We-Are">
                                        <img
                                            className="Banner-who"
                                            src={process.env.PUBLIC_URL + "/assest/image/About/about2.png"}
                                            alt=""
                                        />
                                    </div>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={12}>
                                    <div className="who-Content">
                                        <div className="main">
                                            <div className="points">
                                                <img
                                                    className="points-image"
                                                    src={process.env.PUBLIC_URL + "/assest/image/About/points.png"}
                                                    alt=""
                                                />
                                            </div>
                                            <p className="text">WHO WE ARE</p>
                                        </div>
                                        <p className="text">
                                            Introducing MeetX, the groundbreaking platform that redefines how people connect and interact
                                            in the modern world. MeetX is not just another social networking app; it's a dynamic ecosystem designed to unite
                                            individuals based on shared skills, interests, and passions. With MeetX, the possibilities for meaningful
                                            connections are endless.<br />
                                            <b> Here's how MeetX works:</b> users create profiles highlighting their skills, interests, and preferences. Whether you're passionate about photography, coding, hiking, or cooking, there's a place for you on MeetX. The platform's advanced algorithms then match users with others nearby who share similar interests, making it easy to find like-minded individuals in your area.
                                        </p>
                                        <div className="border-content"></div>
                                    </div>
                                    <Row className="Main">
                                        <Col xxl={6} xl={6} lg={12} md={12}>
                                            <div className="who-Content">
                                                <div className="main">
                                                    <div className="points">
                                                        <img
                                                            className="points-image"
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assest/image/About/points.png"
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <p className="text">OUR VISION</p>
                                                </div>
                                                <p className="text mt-xxl-3 mt-xl-3 mt-lg-1 mt-md-1 mt-1">
                                                    MeetX isn't just about matching; it's a community hub where users bond over activities and events.
                                                    Whether it's meetups, workshops, or group projects, MeetX offers endless chances for connection and
                                                    collaboration. What sets MeetX apart is its emphasis on genuine interactions. Unlike typical social
                                                    platforms, MeetX values authenticity, encouraging users to be themselves for deeper connections.
                                                    Safety is paramount, with robust security measures ensuring user privacy. With customizable privacy
                                                    settings, users have full control over their shared information.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={12} md={12}>
                                            <div className="who-Content">
                                                <div className="main">
                                                    <div className="points">
                                                        <img
                                                            className="points-image"
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assest/image/About/points.png"
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <p className="text">OUR MISSION</p>
                                                </div>
                                                <p className="text mt-xxl-3 mt-xl-3 mt-lg-1 mt-md-1 mt-1">
                                                    At the heart of MeetX is the belief that genuine human connections are essential for personal growth,
                                                    happiness, and well-being. In a world where digital communication often dominates, MeetX offers a refreshing
                                                    alternative—a platform that facilitates real-world interactions and fosters genuine relationships.
                                                    Whether you're new to town and looking to meet people with similar interests, or you simply want to
                                                    expand your social circle and try new activities,  Let's unite in crafting a community where genuine
                                                    connections flourish and enriching encounters prevail.
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        {/* </Container> */}
                    </div>
                </Container>
            </section>
        </>
    );
};

export default WhoWeAre;
